import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from '@solid-ui-layout/Layout';
import { Container } from 'theme-ui';
import Divider from '@solid-ui-components/Divider';
import { Footer } from '../../components/Footer';
import { Header } from '../../components/Header';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <Layout {...props}>
    <Header />
    <Divider space='6' />
    <Container>{props.children}</Container>
    <Divider space='5' />
    <Footer />
  </Layout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "simple-review-management-privacy-policy"
    }}>{`Simple Review Management Privacy Policy`}</h1>
    <p>{`Last updated: October 19, 2020`}</p>
    <p>{`This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from `}<a parentName="p" {...{
        "href": "https://simplereviewmanagement.com"
      }}>{`https://simplereviewmanagement.com`}</a>{` (the “Site”). We are committed to maintaining the accuracy, confidentiality, and security of your personally identifiable information (“Personal Information”). As part of this commitment, our privacy policy governs our actions as they relate to the collection, use and disclosure of Personal Information. Our privacy policy is based on the values set by the Canadian Standards Association’s Model Code for the Protection of Personal Information and Canada’s Personal Information Protection and Electronic Documents Act.`}</p>
    <p>{`We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.`}</p>
    <h3 {...{
      "id": "accountability"
    }}>{`ACCOUNTABILITY`}</h3>
    <p>{`We are responsible for maintaining and protecting the Personal Information under our control. We have designated an individual or individuals who is/are responsible for compliance with our privacy policy.`}</p>
    <h3 {...{
      "id": "identifying-purpouses-for-collecting-information"
    }}>{`IDENTIFYING PURPOUSES FOR COLLECTING INFORMATION`}</h3>
    <p>{`We collect, use and disclose Personal Information to provide you with the product or service you have requested and to offer you additional products and services we believe you might be interested in. The purposes for which we collect Personal Information will be identified before or at the time we collect the information. In certain circumstances, the purposes for which information is collected may be clear, and consent may be implied, such as where your name, address and payment information is provided as part of the order process.`}</p>
    <p>{`When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information about the individual web pages you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as “Device Information.”`}</p>
    <p>{`We collect Device Information using the following technologies:`}</p>
    <p>{`“Log files” track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.
“Web beacons,” “tags,” and “pixels” are electronic files used to record information about how you browse the Site.
Additionally when you make a purchase or attempt to make a purchase through the Site, we collect certain information from you, including your name, billing address, payment information (including credit card numbers), email address, and phone number. We refer to this information as “Order Information.”`}</p>
    <p>{`When we talk about “Personal Information” in this Privacy Policy, we are talking both about Device Information and Order Information.`}</p>
    <h3 {...{
      "id": "how-do-we-use-your-personal-information"
    }}>{`HOW DO WE USE YOUR PERSONAL INFORMATION?`}</h3>
    <p>{`We use the Order Information that we collect generally to fulfill any orders placed through the Site (including processing your payment information, and providing you with invoices and/or order confirmations). Additionally, we use this Order Information to:`}</p>
    <p>{`Communicate with you;
Screen our orders for potential risk or fraud; and
When in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services.
We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our Site (for example, by generating analytics about how our customers browse and interact with the Site, and to assess the success of our marketing and advertising campaigns).`}</p>
    <h3 {...{
      "id": "sharing-your-personal-information"
    }}>{`SHARING YOUR PERSONAL INFORMATION`}</h3>
    <p>{`We share your Personal Information with third parties to help us use your Personal Information, as described above. For example, we use Google Analytics to help us understand how our customers use the Site—you can read more about how Google uses your Personal Information here: `}<a parentName="p" {...{
        "href": "https://www.google.com/intl/en/policies/privacy/"
      }}>{`https://www.google.com/intl/en/policies/privacy/`}</a>{`. You can also opt-out of Google Analytics here: `}<a parentName="p" {...{
        "href": "https://tools.google.com/dlpage/gaoptout"
      }}>{`https://tools.google.com/dlpage/gaoptout`}</a>{`.`}</p>
    <p>{`Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.`}</p>
    <h3 {...{
      "id": "behavioural-advertising"
    }}>{`BEHAVIOURAL ADVERTISING`}</h3>
    <p>{`As described above, we use your Personal Information to provide you with targeted advertisements or marketing communications we believe may be of interest to you. For more information about how targeted advertising works, you can visit the Network Advertising Initiative’s (“NAI”) educational page at `}<a parentName="p" {...{
        "href": "http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work"
      }}>{`http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work`}</a>{`.`}</p>
    <p>{`You can opt out of targeted advertising by:`}</p>
    <ul>
      <li parentName="ul">{`FACEBOOK - `}<a parentName="li" {...{
          "href": "https://www.facebook.com/settings/?tab=ads"
        }}>{`https://www.facebook.com/settings/?tab=ads`}</a></li>
      <li parentName="ul">{`GOOGLE - `}<a parentName="li" {...{
          "href": "https://www.google.com/settings/ads/anonymous"
        }}>{`https://www.google.com/settings/ads/anonymous`}</a></li>
      <li parentName="ul">{`Additionally, you can opt out of some of these services by visiting the Digital Advertising Alliance’s opt-out portal at: `}<a parentName="li" {...{
          "href": "http://optout.aboutads.info/"
        }}>{`http://optout.aboutads.info/`}</a>{`.`}</li>
    </ul>
    <h3 {...{
      "id": "consent"
    }}>{`CONSENT`}</h3>
    <p>{`Knowledge and consent are required for the collection, use or disclosure of Personal Information except where required or permitted by law. Providing us with your Personal Information is always your choice. However, your decision not to provide certain information may limit our ability to provide you with our products or services. We will not require you to consent to the collection, use, or disclosure of information as a condition to the supply of a product or service, except as required to be able to supply the product or service.`}</p>
    <h4 {...{
      "id": "limiting-collection-of-personal-information"
    }}>{`Limiting Collection of Personal Information`}</h4>
    <p>{`The Personal Information collected will be limited to those details necessary for the purposes identified by us. With your consent, we may collect Personal Information from you in person, over the telephone or by corresponding with you via mail, facsimile, or the Internet. Please note that we do not alter our Site’s data collection and use practices when we see a Do Not Track signal from your browser.`}</p>
    <h4 {...{
      "id": "limiting-use-disclosure-and-retention"
    }}>{`Limiting Use, Disclosure and Retention`}</h4>
    <p>{`Personal Information may only be used or disclosed for the purpose for which it was collected unless you have otherwise consented, or when it is required or permitted by law. Personal Information will only be retained for the period of time required to fulfill the purpose for which we collected it or as may be required by law. When you make a purchase through the Site, we will maintain your Order Information for our records unless and until you ask us to delete this information.`}</p>
    <h4 {...{
      "id": "accuracy-of-personal-information"
    }}>{`Accuracy of Personal Information`}</h4>
    <p>{`Personal Information will be maintained in as accurate, complete and up-to-date form as is necessary to fulfill the purposes for which it is to be used.`}</p>
    <h4 {...{
      "id": "safeguarding-customer-information"
    }}>{`Safeguarding Customer Information`}</h4>
    <p>{`Personal Information will be protected by security safeguards that are appropriate to the sensitivity level of the information. We take all reasonable precautions to protect your Personal Information from any loss or unauthorized use, access or disclosure.`}</p>
    <h4 {...{
      "id": "openness-about-privacy"
    }}>{`Openness about Privacy`}</h4>
    <p>{`We will make information available to you about our policies and practices with respect to the management of your Personal Information.`}</p>
    <h4 {...{
      "id": "customer-access"
    }}>{`Customer Access`}</h4>
    <p>{`Upon request, you will be informed of the existence, use and disclosure of your Personal Information, and will be given access to it. You may verify the accuracy and completeness of your Personal Information, and may request that it be amended, if appropriate. However, in certain circumstances permitted by law, we will not disclose certain information to you. For example, we may not disclose information relating to you if other individuals are referenced or if there are legal, security or commercial proprietary restrictions.`}</p>
    <h4 {...{
      "id": "challenging-compliance"
    }}>{`Challenging Compliance`}</h4>
    <p>{`You shall be able to challenge compliance with these principles by contacting us by email at `}<a parentName="p" {...{
        "href": "mailto:support@simplereviewmanagement.com."
      }}>{`support@simplereviewmanagement.com.`}</a>{` We have procedures in place to receive and respond to your complaints or inquiries. We have an obligation to inform our customers who make inquiries about how to access the privacy complaint process. We will investigate each and every complaint made. If a complaint is found to be justified, we will take appropriate measures including, if necessary, amending our policies and practices. Clients will be provided with information about how to contact the Privacy Commissioner of Canada to forward any unresolved complaint. This information is available only upon request.`}</p>
    <p>{`For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at `}<a parentName="p" {...{
        "href": "mailto:support@simplereviewmanagement.com"
      }}>{`support@simplereviewmanagement.com`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      